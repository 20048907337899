import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';
import asyncComponent from './helpers/AsyncFunc';


const RestrictedRoute = ({ component: Component, fromPath,isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            search:`?fromPath=${props.location.pathname.replace(/\//g,'')}`,
            state: { from: props.location }
          }}
        />
      )
    }
  />
)

const PublicRoutes = ({ history, isLoggedIn,fromPath }) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <RestrictedRoute
          exact
          path={'/'}
          isLoggedIn={isLoggedIn}
          component={asyncComponent(() => import('./containers/CustomerHome'))}
        />
        <RestrictedRoute
          exact
          path={'/depositMenu'}
          isLoggedIn={isLoggedIn}
          fromPath={fromPath}
          component={asyncComponent(() => import('./containers/DepositMenu'))}
        />
        <RestrictedRoute
          exact
          path={'/depositMenu/deposit'}
          isLoggedIn={isLoggedIn}
          component={asyncComponent(() => import('./containers/DepositMenu/auto'))}
        />
        <RestrictedRoute
          exact
          path={'/depositMenu/qr'}
          isLoggedIn={isLoggedIn}
          component={asyncComponent(() => import('./containers/DepositMenu/qr'))}
        />
        <RestrictedRoute
          exact
          path={'/depositMenu/slip'}
          isLoggedIn={isLoggedIn}
          component={asyncComponent(() => import('./containers/DepositMenu/slip'))}
        />
        <RestrictedRoute
          exact
          path={'/withdraw'}
          isLoggedIn={isLoggedIn}
          component={asyncComponent(() => import('./containers/Withdraw'))}
        />
        <RestrictedRoute
          exact
          path={'/paymentHistory'}
          isLoggedIn={isLoggedIn}
          component={asyncComponent(() => import('./containers/PaymentHistory'))}
        />
        <RestrictedRoute
          exact
          path={'/upload'}
          isLoggedIn={isLoggedIn}
          component={asyncComponent(() => import('./containers/DepositMenu/upload'))}
        />
        <Route
          exact
          path={'/login'}
          component={asyncComponent(() => import('./containers/CustomerAuthen'))}
        />
      </div>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null
}))(PublicRoutes);
